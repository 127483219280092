import React from 'react';
import {Container} from 'react-bootstrap';
import '../../styles/StoryContent.css'

const Contact = () => {
    return (<Container fluid className='text-center'>
                <h1 className="text-center mb-3">Contact</h1>
                <body className='story text-center mb-3'>
                Plz Don't...
                </body>
            </Container>)
}

export default Contact;
